import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { ButtonSecondary } from '../../../components/atoms/Button/ButtonSecondary'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="業績・財務ハイライト"
      headingEnglish={ENGLISH_TITLE.Finance}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '業績・財務ハイライト', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white">
        <div className="pc:flex flex-wrap">
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pr-3">
            <ButtonSecondary
              label="業績ハイライト"
              url="/ir/performance/highlight"
            />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pl-3">
            <ButtonSecondary label="財政状態" url="/ir/performance/finance" />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pr-3">
            <ButtonSecondary
              label="キャッシュフローの状況"
              url="/ir/performance/cashflow"
            />
          </div>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="業績・財務ハイライト" url="/ir/performance" />
)
